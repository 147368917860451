import { sendGAPageView } from "@api/analytics";
import { batiment } from "@tsTypes/mySitesTypes";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Panel3d from "./components/3dPanel/Panel3d";
import NavBarLogged from "@components/navBarLogged/NavbarLogged";
import Header from "@components/header/Header";
import "./dashboardBatiment.scss";
import MapPanel from "./components/mapPanel/MapPanel";

export default function DashboardBatiment() {
    document.title = "kWh50 - Dashboard Batiment";

    //on récupère le batiment courant
    const { state } = useLocation();

    const navigate = useNavigate();

    const [currentBatiment, setCurrentBatiment] = useState<batiment>();

    useEffect(() => {
        sendGAPageView(document.title, window);

        if (state) {
            setCurrentBatiment(state.batiment as batiment);
        } else {
            navigate("/user/mes-sites");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="dashboard-batiment">
            <Header title={(state.batiment as batiment).nom_bat} />
            <NavBarLogged />
            <main>
                <Link
                    to="/user/mon-batiment"
                    state={{batiment: state.batiment, nomSite: state.nomSite}}
                >
                    Mes consos en détail
                </Link>
                <section className="dashboard">
                    {state.batiment.id_smplr && currentBatiment ? (
                        <Panel3d batiment={currentBatiment} />
                    ) : (
                        <p>Pas de visualisation 3d</p>
                    )}
                    <MapPanel batiment={currentBatiment} />
                </section>
            </main>
        </div>
    );
}
