import { batiment } from "@tsTypes/mySitesTypes";
import { differenceInDays, format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export function usePlotbands(
    startTimestamp: number,
    endTimestamp: number,
    currentBatiment: batiment
) {
    const [data, setData] = useState<any>(null);

    const startDate = format(new Date(startTimestamp), "yyyy-MM-dd");
    const endDate = format(new Date(endTimestamp), "yyyy-MM-dd");

    const plotbands = useMemo(() => {
        let res = [] as Highcharts.AxisPlotBandsOptions[];

        //si on a 7 jours ou moins sur le graphique, on affiche les nuits
        if (
            differenceInDays(
                new Date(endTimestamp),
                new Date(startTimestamp)
            ) <= 7
        ) {
            //on ajout la première nuit
            res.push({
                from: startTimestamp,
                to: new Date(data?.daily?.sunrise[0]).getTime(),
                label: {
                    text: "Nuit",
                    style: {
                        color: "#56872f",
                    },
                },
            });

            //on ajoute les autres nuits
            for (let i = 0; i < data?.daily?.sunrise.length - 1; i++) {
                res.push({
                    from: new Date(data?.daily?.sunset[i]).getTime(),
                    to: new Date(data?.daily?.sunrise[i + 1]).getTime(),
                    label: {
                        text: "Nuit",
                        style: {
                            color: "#56872f",
                        },
                    },
                });
            }

            //on ajoute la dernière nuit uniquement si le timestamp de fin est supérieur au dernier coucher de soleil
            if (
                endTimestamp >
                new Date(data?.daily?.sunset[data?.daily?.sunset.length - 1]).getTime()
            ) {
                res.push({
                    from: new Date(
                        data?.daily?.sunset[data?.daily?.sunset.length - 1]
                    ).getTime(),
                    to: endTimestamp,
                    label: {
                        text: "Nuit",
                        style: {
                            color: "#56872f",
                        },
                    },
                });
            }
        }

        return res;
    }, [data, endTimestamp, startTimestamp]);

    useEffect(() => {
        axios
            .get(
                `https://archive-api.open-meteo.com/v1/archive?latitude=${currentBatiment.latitude}&longitude=${currentBatiment.longitude}&start_date=${startDate}&end_date=${endDate}&daily=sunrise,sunset&timezone=Europe%2FBerlin`
            )
            .then((res) => {
                setData(res.data);
            });
    }, [startTimestamp, endTimestamp, currentBatiment, endDate, startDate]);

    return plotbands;
}
