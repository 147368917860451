import { loadSmplrJs } from "@smplrspace/smplr-loader";
import { Space } from "@smplrspace/smplr-loader/dist/generated/smplr";
import React, { memo } from "react";
import "./viewer.scss";

interface ViewerProps {
    onReady: (space: Space) => void;
    spaceIdBatiment: string;
}

const Viewer = memo(({ onReady, spaceIdBatiment }: ViewerProps) => {
    loadSmplrJs("umd")
        .then((smplr) => {
            const space = new smplr.Space({
                spaceId: spaceIdBatiment,
                clientToken: "pub_5e459d2d172140c69f966900315f1286",
                containerId: "viewer",
            });

            space.startViewer({
                preview: true,
                allowModeChange: true,
                loadingMessage: "⌛ Chargement... ⌛",
                onReady() {
                    onReady(space);
                },
            });
        })
        .catch((error) => console.error(error));

    return <div id="viewer" />;
});

export default Viewer;
