import { AssetRoom } from "@tsTypes/smplrspace";
import { calculateComfortIndex, determineHumidityTooltipColor, determineRoomColor, determineTempTooltipColor } from "@utils/dashboardBatiment";
import React from "react";

interface props {
    d: AssetRoom;
}

export default function Tooltip({ d }: props) {

    let comfortIndex = calculateComfortIndex(d.temperature, d.humidite);

    return (
        <div>
            <p>{d.description}</p>
            <p>
                Temperature:{" "}
                <b style={{ color: determineTempTooltipColor(d.temperature) }}>
                    {d.temperature}
                </b>
                °C
            </p>
            <p>
                Humidite:{" "}
                <b style={{ color: determineHumidityTooltipColor(d.humidite) }}>
                    {d.humidite}
                </b>
                %
            </p>
            <p>
                Indice de confort:{" "}
                <b style={{ color: determineRoomColor(comfortIndex) }}>
                    {comfortIndex}
                </b>
                {} (parfait: 0)
            </p>
        </div>
    );
}
