import { sensor, sensorId, sensorUnite } from "@tsTypes/sensorType";
import React, { useMemo } from "react";
import "../selectMeasure/selectMeasure.scss";

interface props {
    data: sensor[];
    setCurrentSensor: (sensor: sensor) => void;
    currentSensor: sensor;
}

/**
 * @brief Composant permettant de sélectionner un capteur
 * @param batiment : batiment courrant
 */
export default function SelectSensor({
    data,
    setCurrentSensor,
    currentSensor,
}: props) {
    //on récupère les capteurs sans doublons
    const sensors = useMemo(() => {
        if (!data) return;

        //retourne un tableau de capteurs sans doublons
        const reducer = (acc: any, item: sensor) => {
            if (
                !acc.some((el: sensor) => el.id_compteur === item.id_compteur)
            ) {
                acc.push({
                    id_compteur: item.id_compteur,
                    description: item.description,
                    //permet de savoir si le capteur est un capteur de confort afin de le trier dans sa catégorie
                    is_comfort: [
                        "%",
                        "PPM",
                        "Pa",
                        "lm",
                        "personne(s)",
                        "°C",
                    ].some((unite) => unite === item.unite),
                });
            }
            return acc;
        };

        //si le capteur courant est un sous compteur on retourne un tableau avec le capteur courant
        if (currentSensor?.sous_compteur) {
            return [currentSensor];
        }

        return data.reduce(reducer, []);
    }, [data, currentSensor]);
    return (
        <select
            name="sensor"
            id="select-sensor"
            //on change le capteur courant
            onChange={(e) => {
                let res = data.find(
                    (sensor: sensor) =>
                        sensor.id_compteur === e.target.value &&
                        sensor.type_energie === currentSensor.type_energie
                );

                if (res) {
                    setCurrentSensor(res);
                } else {
                    setCurrentSensor(
                        data.find(
                            (sensor: sensor) =>
                                sensor.id_compteur === e.target.value
                        )
                    );
                }
            }}
        >
            {sensors?.filter(
                (sensor: {
                    description: string;
                    id_compteur: sensorId;
                    is_comfort: boolean;
                }) => !sensor.is_comfort
            ).length > 0 && (
                <optgroup label="Énergies finales">
                    {sensors
                        ?.filter(
                            (sensor: {
                                description: string;
                                id_compteur: sensorId;
                                is_comfort: boolean;
                            }) => !sensor.is_comfort
                        )
                        .map(
                            (
                                sensor: {
                                    description: string;
                                    id_compteur: sensorId;
                                    is_comfort: boolean;
                                },
                                index: number
                            ) => (
                                <option
                                    key={index}
                                    value={sensor.id_compteur}
                                    selected={
                                        currentSensor?.id_compteur ===
                                        sensor.id_compteur
                                    }
                                >
                                    {sensor.description}
                                </option>
                            )
                        )}
                </optgroup>
            )}
            {!currentSensor?.sous_compteur &&
                sensors?.filter(
                    (sensor: {
                        description: string;
                        id_compteur: sensorId;
                        is_comfort: boolean;
                    }) => sensor.is_comfort
                ).length > 0 && (
                    <optgroup label="Confort">
                        {sensors
                            ?.filter(
                                (sensor: {
                                    description: string;
                                    id_compteur: sensorId;
                                    is_comfort: boolean;
                                }) => sensor.is_comfort
                            )
                            .map(
                                (
                                    sensor: {
                                        description: string;
                                        id_compteur: sensorId;
                                        is_comfort: boolean;
                                    },
                                    index: number
                                ) => (
                                    <option
                                        key={index}
                                        value={sensor.id_compteur}
                                        selected={
                                            currentSensor?.id_compteur ===
                                            sensor.id_compteur
                                        }
                                    >
                                        {sensor.description}
                                    </option>
                                )
                            )}
                    </optgroup>
                )}
        </select>
    );
}
