import React from "react";
import "./sass/main.scss";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WelcomePage from "@pages/welcomePage/WelcomePage.tsx";
import Login from "@pages/loginPage/LoginPage.tsx";
import Private from "@pages/private/Private.tsx";
import MesSitesPage from "@pages/private/user/mySitesPage/MySitesPage.tsx";
import MyBatiment from "@pages/private/user/myBatiment/MyBatiment.tsx";
import CalibrationPage from "@pages/private/user/calibrationPage/CalibrationPage.tsx";
import DashboardPage from "@pages/private/user/dashboardPage/DashBoardPage.tsx";
import Highcharts from "highcharts/highstock";
import Logout from "@components/logout/Logout.tsx";
import MapPage from "@pages/private/user/mapPage/MapPage.tsx";
import NotFoundPage from "@pages/404/NotFoundPage.tsx";
import "mapbox-gl/dist/mapbox-gl.css";
import DashboardBatiment from "@pages/private/user/dashboardBatiment/DashboardBatiment";

function App() {
    Highcharts.setOptions({
        lang: {
            loading: "Chargement ...",
            months: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
            ],
            weekdays: [
                "Dimanche",
                "Lundi",
                "Mardi",
                "Mercredi",
                "Jeudi",
                "Vendredi",
                "Samedi",
            ],
            shortMonths: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novenbre",
                "Décembre",
            ],
        },
        time: {
            useUTC: false,
        }
    });

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route
                            index
                            element={<WelcomePage />}
                        />
                        <Route
                            path="login"
                            element={<Login />}
                        />
                    </Route>
                    <Route
                        path="/user"
                        element={<Private />}
                    >
                        <Route
                            path="mes-sites"
                            element={<MesSitesPage />}
                        />
                        <Route
                            path="mon-batiment"
                            element={<MyBatiment />}
                        />
                        <Route
                            path="etalonnage"
                            element={<CalibrationPage />}
                        />
                        <Route
                            path="dashboard"
                            element={<DashboardPage />}
                        />
                        <Route
                            path="logout"
                            element={<Logout />}
                        />
                        <Route
                            path="ma-carte"
                            element={<MapPage />}
                        />
                        <Route 
                            path="dashboard-batiment"
                            element={<DashboardBatiment />}
                        />
                    </Route>
                    <Route
                        path="*"
                        element={<NotFoundPage />}
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
