export function determineRoomColor(confortIndex: number) {
    if(confortIndex <= 3) return "#33cc33";
    if(confortIndex <= 6) return "#ff9900";

    return "#ff3300";
}

export function determineTempTooltipColor(temperature: number) {
    if(temperature <= 20 && temperature >= 18) return "#33cc33";
    if(temperature <= 23 && temperature >= 21) return "#ff9900";
    if(temperature >= 22) return "#ff3300";
    if(temperature <= 17 && temperature >= 16) return "#3399ff";
    if(temperature <= 15) return "#0033cc";
}

export function determineHumidityTooltipColor(humidity: number) {
    if(humidity <= 60 && humidity >= 40) return "#33cc33";
    if(humidity <= 70 && humidity >= 30) return "#ff9900";
    return "#ff3300";
}

/**
 * @brief Calcul de l'indice de confort de la piece en fonction de la différence de température et d'humidité par
 * rapport aux valeurs idéales.
 * @param temperature : number
 * @param humidity : number
 * @returns index : number -> index de confort de la piece
 */
export function calculateComfortIndex(temperature: number, humidity: number) {
    let index = 0;

    const IDEAL_TEMPERATURE = 19;
    const IDEAL_HUMIDITY = {
        MIN: 40,
        MAX: 60,
    };

    let difHumidity: number = 0;
    let difTemperature: number = Math.abs(temperature - IDEAL_TEMPERATURE);

    if(humidity < IDEAL_HUMIDITY.MIN){
        difHumidity = IDEAL_HUMIDITY.MIN - humidity;
    } else if(humidity > IDEAL_HUMIDITY.MAX){
        difHumidity = humidity - IDEAL_HUMIDITY.MAX;
    }

    index = difTemperature + (difHumidity * 0.3);

    return parseFloat(index.toFixed(2));
}
