import fetchApi from "@api/fetchApi";
import { useEffect, useMemo, useState } from "react";

export function useDataFetch(url: string, params?: any) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // eslint-disable-next-line
    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    useEffect(() => {
        setIsLoading(true);
        fetchApi.get(url, params).then((res) => {
            setData(res.data);
        }).catch((err) => {
            setError(err);
        }).finally(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line
    }, [memoizedParams]);

    return { data, isLoading, error };
}
