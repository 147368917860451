import { batiment } from "@tsTypes/mySitesTypes";
import React, { useCallback, useEffect } from "react";
import "./panel3d.scss";
import useSWR from "swr";
import { GetFetcher } from "@api/SwrFetcher";
import Tooltip from "./components/tooltip/Tooltip";
import {
    calculateComfortIndex,
    determineRoomColor,
} from "@utils/dashboardBatiment";
import { AssetRoom } from "@tsTypes/smplrspace";
import { Space } from "@smplrspace/smplr-loader/dist/generated/smplr";
import Viewer from "./components/viewer/Viewer";
import { Switch } from "@chakra-ui/react";

interface props {
    batiment: batiment;
}

export default function Panel3d({ batiment }: props) {
    const { data, isLoading } = useSWR(
        {
            url: "/dashboard-batiment/get-room-datas",
            params: { idbat: batiment.id_bat },
        },
        GetFetcher
    );

    const [space, setSpace] = React.useState<Space>();

    const onReady = useCallback((space: Space) => setSpace(space), []);

    useEffect(() => {
        if (!data || !space) return;

        let piecesData: AssetRoom[] = data.data;

        space.addDataLayer({
            id: "pieces",
            data: piecesData as any,
            type: "polygon",
            color: (d: any) =>
                determineRoomColor(
                    calculateComfortIndex(d.temperature, d.humidite)
                ),
            alpha: 0.5,
            tooltip: (d: any) => (<Tooltip d={d} />) as unknown as string,
        });

        return () => {
            space.removeDataLayer("pieces");
        };
    }, [data, space]);

    return (
        <>
            {isLoading ? (
                <p>Chargement</p>
            ) : (
                <div className="panel-3d">
                    <div className="switch-mode">
                        <p>Activer le mode 2D</p>
                        <Switch
                            colorScheme="green"
                            disabled={space ? false : true}
                            onChange={(e) =>
                                e.target.checked
                                    ? space.setMode("2d")
                                    : space.setMode("3d")
                            }
                        />
                    </div>
                    <Viewer
                        onReady={onReady}
                        spaceIdBatiment={batiment.id_smplr}
                    />
                </div>
            )}
        </>
    );
}
